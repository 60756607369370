import Model from "./Model";

export default class User extends Model {
  resource() {
    return "users";
  }

  static default(data) {
    return {
      civilite: "",
      first_name: "",
      last_name: "",
      user_email: "",
      user_pass: "",
      ville: "",
      cp: "",
      rpps: "",
      optin: false,
      reminders: false,
      mobile: "",
      etablissement: null,
      specialite: null,
      profession: null,
      exercice: null,
      titre: null,
      isLaboratoire: false,
      invitation: null,
    };
  }

  primaryKey() {
    return "ID";
  }

  get isDoctorInFrance() {
    return this.profession === "Médecin exerçant en France";
  }
  get isDoctor() {
    return (
      this.profession === "Médecin exerçant en France" ||
      this.profession === "Médecin exerçant hors de France"
    );
  }

  updatePassword() {
    return this.request(
      this._reqConfig(
        {
          method: "POST",
          url: `${this.endpoint()}/password`,
          data: this,
        },
        { forceMethod: true }
      )
    ).then((response) => {
      return this._applyInstance(response.data.data || response.data);
    });
  }
}
