var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('user-account-form',{attrs:{"custom-fields":_vm.customFields,"user":_vm.contact},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"end",fn:function(){return [(
          _vm.getCustom(
            'invitation',
            ['evenement_form_display', 'display'],
            false
          )
        )?_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col"},[_c('label',{attrs:{"for":"invitation"}},[_vm._v("\n            "+_vm._s(_vm.$t("form.invitation"))+"\n          ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.invitation),expression:"contact.invitation"}],attrs:{"required":_vm.getCustom(
                'invitation',
                ['evenement_form_required', 'required'],
                false
              ),"type":"text","name":"invitation"},domProps:{"value":(_vm.contact.invitation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "invitation", $event.target.value)}}})])]):_vm._e(),_vm._v(" "),(_vm.getCustom('optin', ['evenement_form_display', 'display'], true))?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('label',{attrs:{"for":"optin"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.optin),expression:"contact.optin"}],staticClass:"ct-checkbox",attrs:{"id":"optin","name":"optin","type":"checkbox","required":_vm.getCustom(
                  'optin',
                  ['evenement_form_required', 'required'],
                  true
                )},domProps:{"checked":Array.isArray(_vm.contact.optin)?_vm._i(_vm.contact.optin,null)>-1:(_vm.contact.optin)},on:{"change":function($event){var $$a=_vm.contact.optin,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.contact, "optin", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.contact, "optin", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.contact, "optin", $$c)}}}}),_vm._v("\n            "+_vm._s(_vm.getCustom("optin", ["evenement_form_label", "label"], "") ||
              _vm.$t("form.optin"))+"\n            "+_vm._s(_vm.getCustom(
                "optin",
                ["evenement_form_required", "required"],
                true
              )
                ? "*"
                : "")+"\n          ")])])]):_vm._e(),_vm._v(" "),(
          _vm.getCustom('optin_cgu', ['evenement_form_display', 'display'], true)
        )?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('label',{attrs:{"for":"optin_cgu"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.optin_cgu),expression:"contact.optin_cgu"}],staticClass:"ct-checkbox",attrs:{"id":"optin_cgu","name":"optin_cgu","type":"checkbox","required":_vm.getCustom(
                  'optin_cgu',
                  ['evenement_form_required', 'required'],
                  true
                )},domProps:{"checked":Array.isArray(_vm.contact.optin_cgu)?_vm._i(_vm.contact.optin_cgu,null)>-1:(_vm.contact.optin_cgu)},on:{"change":function($event){var $$a=_vm.contact.optin_cgu,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.contact, "optin_cgu", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.contact, "optin_cgu", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.contact, "optin_cgu", $$c)}}}}),_vm._v("\n            "+_vm._s(_vm.getCustom("optin_cgu", ["evenement_form_label", "label"], "") ||
              _vm.$t("form.optin_cgu"))+"\n            "+_vm._s(_vm.getCustom(
                "optin_cgu",
                ["evenement_form_required", "required"],
                true
              )
                ? "*"
                : "")+"\n          ")])])]):_vm._e()]},proxy:true},{key:"button",fn:function(){return [_c('p',{staticClass:"text-end"},[_c('button',{staticClass:"ct-button",attrs:{"type":"submit"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" "),_c('span',{class:{ 'visually-hidden': _vm.loading }},[_vm._v("\n            "+_vm._s(_vm.$t("form.validate_register")))])])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }