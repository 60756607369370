<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade show os-modal-register"
      style="display: block"
      tabindex="-1"
      :class="{
        open: modalOpen,
        'd-none': hideEmptyModal && events.length === 0,
      }"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div
            class="modal-header border-top border-2 shadow-sm bg-white"
            @click="onOpenModal"
          >
            <div class="ct-container d-flex">
              <div class="flex-grow-1 d-flex align-items-center">
                <strong class="me-2">{{ events.length }} </strong>
                <span>
                  {{
                    events.length > 1
                      ? "évènements séléctionnés"
                      : "évènement séléctionné"
                  }}
                </span>
              </div>
              <div class="">
                <button class="ct-button">
                  {{ modalOpen ? "Fermer" : "Valider mes inscriptions" }}
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="ct-container">
              <div v-show="events.length === 0" class="p-4 text-center">
                <p>
                  Commencez par sélectionner un ou des évènements dans la liste
                  en cliquant sur "M'inscrire".
                </p>
              </div>
              <div class="row" :class="{ 'd-none': events.length === 0 }">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg-white">
                      <strong>
                        {{ $t("summary") }}
                      </strong>
                    </div>
                    <div class="card-body">
                      <div
                        class="text-sm text-gray-500"
                        v-for="event in events"
                        :key="event.ID"
                      >
                        <p class="m-0 text-lg">
                          <b>{{ event.post_title }}</b>
                        </p>
                        <ul class="fa-ul mb-0" style="margin-left: 1.5rem">
                          <li v-if="event.acf.evenement_date.date">
                            <span class="fa-li">
                              <i class="fas fa-calendar-day"></i> </span
                            >Le
                            {{
                              dateFormat(event.acf.evenement_date.date, {
                                timezome: "UTC",
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })
                            }}
                            à
                            {{
                              dateFormat(event.acf.evenement_date.date, {
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                            }}
                          </li>
                          <li v-if="event.acf.evenement_orateurs">
                            <span class="fa-li">
                              <i class="fas fa-chalkboard-teacher"></i> </span
                            >Par {{ event.acf.evenement_orateurs }}
                          </li>
                          <li v-if="event.acf.addresse">
                            <span class="fa-li">
                              <i class="fas fa-map-marker-alt"></i> </span
                            >{{ event.acf.addresse }}
                          </li>
                        </ul>

                        <div v-html="event.content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg-white">
                      <strong> {{ $t("your_information") }}: </strong>
                    </div>
                    <div class="card-body">
                      <evenement-form
                        :evenementIds="eventIds"
                        :custom-fields="customFields"
                      ></evenement-form>
                    </div>
                    <hr />
                    <div class="card-body">
                      <slot name="conditions"></slot>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- overlay -->
    <div class="modal-backdrop fade show" v-show="modalOpen"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import EvenementForm from "./EvenementForm.vue";
import Format from "../mixins/Format";
import Form from "../mixins/Form";

export default {
  name: "ModalEvents",
  components: { EvenementForm },
  mixins: [Format, Form],
  data() {
    return {};
  },
  props: {
    hideEmptyModal: Boolean,
    customFields: Array,
  },
  computed: {
    ...mapState(["events", "modalOpen"]),
    ...mapGetters(["eventIds"]),
  },
  watch: {
    modalOpen(newval, oldval) {
      if (newval) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  methods: {
    onSaved() {
      console.log("saved");
    },
    onOk() {
      this.$store.commit("clearEvents");
      this.isSuccess = false;
      this.message = "";
      this.onOpenModal();
    },
    onSubmit() {
      this.loading = true;
    },
    onRemove(event) {
      this.$store.commit("removeEvent", event);
    },
    onOpenModal() {
      this.$store.commit("toggleModal");
    },
  },
};
</script>