<template>
  <div class="bs">
    <div class="card shadow mb-4">
      <div class="card-body">
        <h4 class="card-title">{{ $t("account.my_meetings") }}</h4>
        <div style="max-height: 250px" class="overflow-auto">
          <slot name="evenements"></slot>
        </div>
      </div>
    </div>

    <div class="card shadow mb-4">
      <div class="card-body">
        <h4 class="card-title">{{ $t("account.my_information") }}</h4>
        <div>
          <p>
            <i class="fas fa-info me-2"></i>
            {{ $t("account.my_information_message") }}
          </p>
          <user-account-form
            v-if="user"
            :user="user"
            :loading="loading.user"
            @submit="onSaveUser"
          ></user-account-form>
        </div>
      </div>
    </div>

    <div class="card shadow mb-4">
      <div class="card-body">
        <h4 class="card-title">{{ $t("account.change_password") }}</h4>
        <div>
          <user-password-form v-if="user" :user="user"></user-password-form>
        </div>
      </div>
    </div>

    <div class="card shadow mb-4">
      <div class="card-body">
        <h4 class="card-title text-danger">{{ $t("account.delete") }}</h4>
        <p>{{ $t("account.delete_message") }}</p>
        <button class="ct-button bg-danger" @click="onDelete">
          <span
            v-show="loading.delete"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span :class="{ 'visually-hidden': loading.delete }">{{
            $t("account.delete")
          }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import User from "../models/User";
import UserAccountForm from "./UserAccountForm.vue";
import UserPasswordForm from "./UserPasswordForm.vue";

export default {
  name: "UserAccount",
  components: { UserAccountForm, UserPasswordForm },
  data() {
    return {
      user: null,
      loading: {
        user: false,
        delete: false,
      },
    };
  },
  computed: {},
  methods: {
    async onSaveUser() {
      this.loading.user = true;
      await this.user
        .save()
        .then((user) => {
          this.$emit("saved", user);
          this.$emit("updated", user);
          this.swalToast({
            title: this.$t("Informations enregistréess"),
          });
        })
        .catch((err) => {
          this.loading.user = false;
        });
      this.loading.user = false;
    },
    onDelete() {
      this.swal({
        title: this.$t("account.delete_confirm"),
        text: this.$t("account.delete_message"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("account.delete_confirm_action"),
        cancelButtonText: this.$t("Annuler"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteUser();
        }
      });
    },
    async deleteUser() {
      await this.user.delete().then((value) => {
        this.swal({
          icon: "success",
          title: this.$t("account.deleted"),
          text: this.$t("account.deleted_message"),
          confirmButtonText: "Ok",
          timer: 4000,
          timerProgressBar: true,
        }).then((result) => {
          window.location.href = "/wp-login.php";
        });
      });
    },
    async getUser() {
      await User.$find("current")
        .then((user) => {
          this.user = user;
          console.log(user);
        })
        .catch((error) => {});
    },
  },
  created() {
    this.getUser();
  },
};
</script>
