<template>
  <div class="">
    <user-account-form :user="user" v-on:submit="onSubmit">
      <template #end>
        <div class="row">
          <div class="col">
            <label for="user_pass"> {{ $t("password") }}*</label>
            <input
              type="password"
              :class="{ 'border-danger': !isPasswordValid && wasValidated }"
              id="user_pass"
              v-model="user.user_pass"
              ref="user_pass"
              required
            />
            <small
              :class="{
                'text-danger': !isPasswordValid && wasValidated,
                'text-success': isPasswordValid,
              }"
            >
              {{ passwordVerification }}
            </small>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label for="optin" class="">
              <input
                id="optin"
                name="optin"
                type="checkbox"
                required
                class="ct-checkbox"
                v-model="user.optin"
              />
              {{ $t("accept_cgu") }}
            </label>
          </div>
        </div>
      </template>

      <template #button>
        <button type="submit" class="ct-button">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span :class="{ 'visually-hidden': loading }">{{
            $t("form.create_account")
          }}</span>
        </button>
      </template>
    </user-account-form>
  </div>
</template>

<script>
import User from "../models/User";

import UserAccountForm from "./UserAccountForm.vue";

export default {
  name: "RegisterForm",
  components: { UserAccountForm },
  data() {
    return {
      loading: false,
      wasValidated: false,
      user: new User({
        ...User.default(),
      }),
    };
  },
  computed: {
    passwordVerification() {
      if (this.user.user_pass.length < 6) {
        return this.$t("form.passwords.at_least_6_char");
      } else if (this.user.user_pass.search(/\d/) == -1) {
        return this.$t("form.passwords.at_least_1_number");
      } else if (this.user.user_pass.search(/[a-zA-Z]/) == -1) {
        return this.$t("form.passwords.at_least_1_letter");
      }
      return this.$t("form.passwords.perfect");
    },
    isPasswordValid() {
      return this.passwordVerification === this.$t("form.passwords.perfect");
    },
  },
  methods: {
    verifyRecaptcha() {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(process.env.MIX_reCAPTCHA_site_key, {
            action: "submit",
          })
          .then((token) => {
            this.user.recaptchaToken = token;
            this.onSave();
          });
      });
    },
    onSubmit() {
      this.wasValidated = true;

      if (!this.isPasswordValid) {
        console.log("password is not ok");
        return;
      }

      if (process.env.MIX_RECAPTCHA_ENABLED === "true") {
        this.verifyRecaptcha();
      } else {
        this.onSave();
      }
    },

    async onSave() {
      this.loading = true;
      //be sure to make POST request
      this.user.ID = null;

      await this.user
        .save()
        .then((response) => {
          this.loading = false;

          this.swal({
            icon: "success",
            title: this.$t("account.created"),
            text: this.$t("account.can_login"),
            confirmButtonText: this.$t("account.login"),
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/";
            }
          });
        })
        .catch((err) => {
          this.loading = false;
          this.swal({
            icon: "error",
            title: this.$t("Une erreur est servenue"),
            text: `${err.response.data.message}`,
          });
        });
    },
  },
};
</script>
