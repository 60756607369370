import Model from "./Model";

export default class Contact extends Model {
  resource() {
    return "contacts";
  }

  get isDoctorInFrance() {
    return this.profession == "Médecin exerçant en France";
  }
  get isDoctor() {
    return (
      this.profession == "Médecin exerçant en France" ||
      this.profession == "Médecin exerçant hors de France"
    );
  }

}
