<template>
  <form ref="form" v-on:submit.prevent="onSubmit">
    <slot name="start"></slot>

    <div
      class="row mb-2"
      v-if="getCustom('civilite', 'evenement_form_display', true)"
    >
      <div class="col">
        <label class="">
          {{ $t("form.civility") }}
          <span v-if="getCustom('civilite', 'evenement_form_required', true)"
            >*</span
          >
        </label>
        <div class="d-flex">
          <div class="me-3">
            <input
              v-model="user.civilite"
              :required="getCustom('civilite', 'evenement_form_required', true)"
              type="radio"
              id="civiliteMr"
              name="civilite"
              value="M."
              class="custom-control-input"
            />
            <label for="civiliteMr">
              {{ $t("sir") }}
            </label>
          </div>
          <div>
            <input
              v-model="user.civilite"
              required
              type="radio"
              id="civiliteMme"
              name="civilite"
              value="Mme"
              class="custom-control-input"
            />
            <label for="civiliteMme"> {{ $t("madam") }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div
        class="col"
        v-if="getCustom('first_name', 'evenement_form_display', true)"
      >
        <label for="prenom" class="mb-1">
          {{ $t("first_name") }}
          <span v-if="getCustom('first_name', 'evenement_form_required', true)"
            >*</span
          >
        </label>

        <div class="">
          <input
            :required="getCustom('first_name', 'evenement_form_required', true)"
            type="text"
            name="prenom"
            class=""
            :placeholder="$t('first_name')"
            v-model="user.first_name"
          />
        </div>
      </div>
      <div
        class="col"
        v-if="getCustom('last_name', 'evenement_form_display', true)"
      >
        <label for="nom" class="mb-1">
          {{ $t("last_name") }}
          <span v-if="getCustom('last_name', 'evenement_form_required', true)"
            >*</span
          >
        </label>
        <div class="">
          <input
            :required="getCustom('last_name', 'evenement_form_required', true)"
            type="text"
            name="nom"
            class=""
            :placeholder="$t('last_name')"
            v-model="user.last_name"
          />
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div
        class="col"
        v-if="getCustom('user_email', 'evenement_form_display', true)"
      >
        <label for="email" class="mb-1"
          >{{ $t("email") }}
          <span v-if="getCustom('user_email', 'evenement_form_required', true)"
            >*</span
          >
        </label>
        <div class="">
          <input
            :required="getCustom('user_email', 'evenement_form_required', true)"
            type="email"
            name="email"
            class=""
            :placeholder="$t('email')"
            v-model="user.user_email"
          />
        </div>
      </div>
      <div
        class="col"
        v-if="getCustom('mobile', 'evenement_form_display', true)"
      >
        <label for="mobile" class="">
          {{ $t("phone") }}
          <span v-if="getCustom('mobile', 'evenement_form_required', true)"
            >*</span
          >
          {{ $t("form.phone_label") }}</label
        >
        <div class="mt-1">
          <input
            :required="getCustom('mobile', 'evenement_form_required', true)"
            type="tel"
            name="mobile"
            class=""
            :placeholder="$t('phone')"
            v-model="user.mobile"
          />
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div
        class="col"
        v-if="getCustom('specialite', 'evenement_form_display', true)"
      >
        <label for="specialite" class="mb-1">
          {{ $t("speciality") }}
          <span v-if="getCustom('specialite', 'evenement_form_required', true)"
            >*</span
          >
        </label>

        <select
          :required="getCustom('specialite', 'evenement_form_required', true)"
          name="specialite"
          class="px-3"
          v-model="user.specialite"
        >
          <option
            v-for="speciality in specialities"
            :key="speciality"
            :value="speciality"
          >
            {{ $t(speciality) }}
          </option>
        </select>
      </div>
      <div
        class="col"
        v-if="getCustom('exercice', 'evenement_form_display', true)"
      >
        <label for="exercice" class="mb-1">
          {{ $t("exercice") }}
          <span v-if="getCustom('exercice', 'evenement_form_required', true)"
            >*</span
          >
        </label>

        <select
          :required="getCustom('exercice', 'evenement_form_required', true)"
          name="exercice"
          class="px-3"
          v-model="user.exercice"
        >
          <option
            v-for="exercice in exercices"
            :key="exercice"
            :value="exercice"
          >
            {{ $t(exercice) }}
          </option>
        </select>
      </div>
    </div>

    <div
      class="row mb-2"
      v-if="getCustom('etablissement', 'evenement_form_display', true)"
    >
      <div class="col">
        <label for="etablissement" class="mb-1">
          {{ $t("institution") }}
          <span
            v-if="getCustom('etablissement', 'evenement_form_required', false)"
            >*</span
          >
        </label>

        <div class="">
          <input
            :required="
              getCustom('etablissement', 'evenement_form_required', false)
            "
            type="text"
            name="etablissement"
            class=""
            :placeholder="$t('form.placeholder.institution')"
            v-model="user.etablissement"
          />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div
        class="col"
        v-if="getCustom('profession', 'evenement_form_display', true)"
      >
        <label for="profession" class="mb-1">
          {{ $t("profession") }}
          <span v-if="getCustom('profession', 'evenement_form_required', true)"
            >*</span
          >
        </label>
        <div class="">
          <select
            :required="getCustom('profession', 'evenement_form_required', true)"
            name="profession"
            class="px-3"
            v-model="user.profession"
          >
            <option
              v-for="profession in professions"
              :key="profession"
              :value="profession"
            >
              {{ $t(profession) }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="col"
        v-if="getCustom('titre', 'evenement_form_display', true)"
        v-show="user.isDoctor"
      >
        <label class="">
          {{ $t("title") }}
          <span v-if="getCustom('titre', 'evenement_form_required', true)"
            >*</span
          >
        </label>

        <div class="d-flex">
          <div class="me-3">
            <input
              v-model="user.titre"
              :required="
                user.isDoctor &&
                getCustom('titre', 'evenement_form_required', true)
              "
              type="radio"
              id="titreDr"
              name="titre"
              value="Dr"
              class="custom-control-input"
            />
            <label for="titreDr">Dr</label>
          </div>
          <div>
            <input
              v-model="user.titre"
              :required="
                user.isDoctor &&
                getCustom('titre', 'evenement_form_required', true)
              "
              type="radio"
              id="titrePr"
              name="titre"
              value="Pr"
              class="custom-control-input"
            />
            <label for="titrePr">Pr</label>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row mb-2"
      v-if="getCustom('rpps', 'evenement_form_display', true)"
      v-show="user.isDoctorInFrance"
    >
      <div class="col">
        <label for="rpps" class="mb-1"
          >{{ $t("rpps") }}
          <span v-if="getCustom('rpps', 'evenement_form_required', true)"
            >*</span
          >
        </label>
        <div class="">
          <input
            :required="
              user.isDoctorInFrance &&
              getCustom('rpps', 'evenement_form_required', true)
            "
            type="text"
            name="rpps"
            class=""
            placeholder="N° RPPS"
            v-model="user.rpps"
            minlength="11"
            maxlength="11"
          />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col" v-if="getCustom('cp', 'evenement_form_display', true)">
        <label for="cp" class="mb-1">
          {{ $t("postal_code") }}
          <span v-if="getCustom('cp', 'evenement_form_required', true)">*</span>
        </label>
        <div class="">
          <input
            :required="getCustom('cp', 'evenement_form_required', true)"
            type="text"
            name="cp"
            class=""
            :placeholder="$t('postal_code')"
            v-model="user.cp"
          />
        </div>
      </div>
      <div
        class="col"
        v-if="getCustom('ville', 'evenement_form_display', true)"
      >
        <label for="ville" class="mb-1">
          {{ $t("city") }}
          <span v-if="getCustom('ville', 'evenement_form_required', true)"
            >*</span
          >
        </label>
        <div class="">
          <input
            :required="getCustom('ville', 'evenement_form_required', true)"
            type="text"
            name="ville"
            class=""
            :placeholder="$t('city')"
            v-model="user.ville"
          />
        </div>
      </div>
    </div>

    <slot name="end"></slot>

    <slot name="button" :loading="loading">
      <p class="text-right">
        <button type="submit" class="ct-button">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span :class="{ 'visually-hidden': loading }">{{ $t("save") }}</span>
        </button>
      </p>
    </slot>
  </form>
</template>

<script>
import specialities from "../data/specialities.json";
import professions from "../data/professions.json";
import exercices from "../data/exercices.json";
import User from "../models/User";
import Form from "../mixins/Form";

export default {
  name: "UserAccountForm",
  mixins: [Form],
  props: {
    loading: Boolean,
    user: {
      type: Object,
      default() {
        return new User({ ...User.default() });
      },
    },
  },
  data() {
    return {
      professions,
      specialities,
      exercices,
    };
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$emit("submit", this.user);
    },
  },
  mounted() {},
};
</script>
