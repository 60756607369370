<template>
  <svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCheck",
};
</script>