// Axios & Echo global
require("./bootstrap");

/* Core */
import Vue from "vue";
import Vuex from "vuex";
import VueI18n from "vue-i18n";
import VueGlide from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";

import Swal from "./mixins/Swal";
import UserAccount from "./components/UserAccount.vue";
import RegisterForm from "./components/RegisterForm.vue";
import EvenementForm from "./components/EvenementForm.vue";
import GlideCarousel from "./components/GlideCarousel.vue";
import ModalEvents from "./components/ModalEvents.vue";
import BtnEventsRegister from "./components/BtnEventsRegister.vue";

import VTooltipPlugin from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";
import store from "./store";

VTooltipPlugin.options.themes.tooltip.delay.show = 0;

Vue.use(VueGlide);
Vue.use(VTooltipPlugin);
Vue.use(VueI18n);
Vue.use(VueI18n);
Vue.use(Vuex);

/* This is main entry point */
Vue.component("user-account", UserAccount);
Vue.component("register-form", RegisterForm);
Vue.component("evenement-form", EvenementForm);
Vue.component("glide-carousel", GlideCarousel);
Vue.component("modal-events", ModalEvents);
Vue.component("btn-events-register", BtnEventsRegister);

Vue.mixin(Swal);

const translations = require("./translations.json");
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "fr", // set locale
  fallbackLocale: "fr",
  messages: translations, // set locale messages
});

new Vue({
  el: "#vue",
  i18n,
  store: new Vuex.Store(store),
  mounted() {
    console.log("mounted");
    this.$i18n.locale = document.documentElement.lang;
  },
});
