export default {
  state: {
    modalOpen: false,
    events: [],
  },
  getters: {
    eventIds: (state) => {
      return state.events.map((item) => {
        return item.ID || item.id;
      });
    },
  },
  mutations: {
    openModal(state) {
      state.modalOpen = true;
    },
    toggleModal(state) {
      state.modalOpen = !state.modalOpen;
    },
    clearEvents(state) {
      state.events = [];
    },
    removeEvents(state, events) {
      if (!Array.isArray(events)) events = [events];
      events.forEach((event) => {
        let index = state.events.findIndex((item) => {
          return item.ID == event.ID;
        });
        if (index < 0) {
          return;
        }
        state.events.splice(index, 1);
      });
    },
    pushEvents(state, events) {
      if (!Array.isArray(events)) events = [events];
      events.forEach((event) => {
        if (
          state.events
            .map((item) => {
              return item.ID;
            })
            .includes(event.ID)
        ) {
          return;
        }
        state.events.push(event);
      });
    },
  },
};
