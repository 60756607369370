export default {
  props: {
    customFields: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getCustom(field, keys = null, fallback = null) {
      const customField = this.customFields.find((item) => {
        return item.evenement_form_field === field || item.field === field;
      });
      if (!customField) return fallback;
      if (!Array.isArray(keys)) {
        keys = [keys];
      }

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (Object.hasOwnProperty.call(customField, key)) {
          return customField[key];
        }
      }

      return fallback;
    },
  },
};
