<template>
  <vue-glide
    :per-view="perView"
    :bullet="bullet"
    :bound="bound"
    :breakpoints="breakpoints"
  >
    <template v-slot:default>
      <slot></slot>
    </template>

    <template v-slot:control>
      <button type="button" data-glide-dir="<">
        <!-- <i class="fas fa-chevron-left"></i> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="124"
          viewBox="0 0 19 124"
        >
          <path
            d="M62,0l62,19H0Z"
            transform="translate(0 124) rotate(-90)"
            fill="#47b588"
          />
        </svg>

        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="287"
          viewBox="0 0 19 287"
        >
          <path
            d="M143.5,0,287,19H0Z"
            transform="translate(0 287) rotate(-90)"
            fill="#47b588"
          />
        </svg> -->
      </button>
      <button type="button" data-glide-dir=">">
        <!-- <i class="fas fa-chevron-right"></i> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="124"
          viewBox="0 0 19 124"
        >
          <path
            d="M62,0l62,19H0Z"
            transform="translate(19) rotate(90)"
            fill="#47b588"
          />
        </svg>

        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="287"
          viewBox="0 0 19 287"
        >
          <path
            d="M143.5,0,287,19H0Z"
            transform="translate(19) rotate(90)"
            fill="#47b588"
          />
        </svg> -->
      </button>
    </template>
  </vue-glide>
</template>

<script>
export default {
  name: "GlideCarousel",
  props: {
    perView: { type: Number, default: 3 },
    bullet: { type: Boolean, default: true },
    bound: { type: Boolean, default: true },
    breakpoints: {
      type: Object,
      default: () => ({
        576: {
          perView: 1,
        },
        768: {
          perView: 2,
        },
      }),
    },
  },
};
</script>

<style>
</style>