<template>
  <button type="button" @click="onClick">
    <span
      style="
        width: 20px;
        height: 20px;
        vertical-align: middle;
      "
      :class="[push && active ? 'd-inline-block' : 'd-none']"
    >
      <icon-check></icon-check>
    </span>
    <span v-show="push && active"> Ajouté </span>
    <span v-show="!push || (push && !active)">
      <slot> M'inscrire </slot>
    </span>
  </button>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import IconCheck from "./IconCheck.vue";

export default {
  name: "BtnEventsRegister",
  components: { IconCheck },
  props: {
    events: Array,
    openOnClick: Boolean,
    push: Boolean, // push events to the list and do not replace
  },
  computed: {
    ...mapGetters(["eventIds"]),
    active() {
      return this.events.some((event) => {
        return this.eventIds.includes(event.ID);
      });
    },
  },
  methods: {
    ...mapMutations(["openModal", "clearEvents", "pushEvents", "removeEvents"]),
    onClick() {
      if (this.push) {
        if (this.active) {
          this.removeEvents(this.events);
        } else {
          this.pushEvents(this.events);
        }
      } else {
        this.clearEvents();
        this.pushEvents(this.events);
      }
      if (this.openOnClick) {
        this.openModal();
      }
    },
  },
};
</script>