<template>
  <div class="">
    <form v-on:submit.prevent="onSubmit">
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="password">{{ $t("account.new_password") }}</label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="password"
            required
          />
          <small class="text-muted">
            {{ passwordVerification }}
          </small>
        </div>

        <div class="col-md-6">
          <label for="password_confirmation">{{
            $t("account.confirm_password")
          }}</label>
          <input
            type="password"
            class="form-control"
            id="password_confirmation"
            v-model="password_confirmation"
            required
          />
          <small class="text-muted" v-show="isPasswordValid">
            {{
              areSamePassword
                ? "Tout est parfait"
                : "Les 2 mots de passes ne correspondent pas..."
            }}
          </small>
        </div>
      </div>

      <button :disabled="loading" type="submit" class="ct-button">
        <span
          v-show="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span :class="{ 'visually-hidden': loading }">{{
          $t("account.change_password")
        }}</span>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "UserPasswordForm",
  props: {
    user: Object,
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      loading: false,
    };
  },
  computed: {
    areSamePassword() {
      return this.password === this.password_confirmation;
    },
    passwordVerification() {
      if (this.password.length < 6) {
        return this.$t("form.passwords.at_least_6_char");
      } else if (this.password.search(/\d/) == -1) {
        return this.$t("form.passwords.at_least_1_number");
      } else if (this.password.search(/[a-zA-Z]/) == -1) {
        return this.$t("form.passwords.at_least_1_letter");
      }
      return this.$t("form.passwords.perfect");
    },
    isPasswordValid() {
      return this.passwordVerification === this.$t("form.passwords.perfect");
    },
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      this.user.password = this.password;
      this.user.password_confirmation = this.password_confirmation;

      await this.user
        .updatePassword()
        .then((response) => {
          this.loading = false;

          this.password = "";
          this.password_confirmation = "";

          this.swal({
            icon: "success",
            title: this.$t("form.passwords.changed"),
            text: this.$t("form.passwords.changed_login"),
            confirmButtonText: "Me connecter",
            timer: 4000,
            timerProgressBar: true,
          }).then((result) => {
            window.location.href = "/wp-login.php";
          });
        })
        .catch((err) => {
          this.loading = false;
          this.swal({
            icon: "error",
            title: this.$t("Une erreur est servenue"),
            text: `${err.response.data.message}`,
          });
        });
    },
  },
};
</script>
