<template>
  <div class="">
    <user-account-form
      :custom-fields="customFields"
      :user="contact"
      v-on:submit="onSubmit"
    >
      <template #end>
        <div
          class="row mb-2"
          v-if="
            getCustom(
              'invitation',
              ['evenement_form_display', 'display'],
              false
            )
          "
        >
          <div class="col">
            <label for="invitation">
              {{ $t("form.invitation") }}
            </label>
            <input
              :required="
                getCustom(
                  'invitation',
                  ['evenement_form_required', 'required'],
                  false
                )
              "
              type="text"
              name="invitation"
              v-model="contact.invitation"
            />
          </div>
        </div>

        <!-- <div
          class="row mb-2"
          v-if="getCustom('questions', 'evenement_form_display', true)"
        >
          <div class="col">
            <label for="questions">
              {{ $t("form.questions") }}
            </label>
            <input type="text" name="questions" v-model="contact.questions" />
          </div>
        </div> -->
        <div
          class="row mb-3"
          v-if="getCustom('optin', ['evenement_form_display', 'display'], true)"
        >
          <div class="col">
            <label for="optin" class="">
              <input
                id="optin"
                name="optin"
                type="checkbox"
                :required="
                  getCustom(
                    'optin',
                    ['evenement_form_required', 'required'],
                    true
                  )
                "
                class="ct-checkbox"
                v-model="contact.optin"
              />
              {{
                getCustom("optin", ["evenement_form_label", "label"], "") ||
                $t("form.optin")
              }}
              {{
                getCustom(
                  "optin",
                  ["evenement_form_required", "required"],
                  true
                )
                  ? "*"
                  : ""
              }}
            </label>
          </div>
        </div>
        <div
          class="row mb-3"
          v-if="
            getCustom('optin_cgu', ['evenement_form_display', 'display'], true)
          "
        >
          <div class="col">
            <label for="optin_cgu" class="">
              <input
                id="optin_cgu"
                name="optin_cgu"
                type="checkbox"
                :required="
                  getCustom(
                    'optin_cgu',
                    ['evenement_form_required', 'required'],
                    true
                  )
                "
                class="ct-checkbox"
                v-model="contact.optin_cgu"
              />
              {{
                getCustom("optin_cgu", ["evenement_form_label", "label"], "") ||
                $t("form.optin_cgu")
              }}
              {{
                getCustom(
                  "optin_cgu",
                  ["evenement_form_required", "required"],
                  true
                )
                  ? "*"
                  : ""
              }}
            </label>
          </div>
        </div>
      </template>

      <template #button>
        <p class="text-end">
          <button type="submit" class="ct-button">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span :class="{ 'visually-hidden': loading }">
              {{ $t("form.validate_register") }}</span
            >
          </button>
        </p>
      </template>
    </user-account-form>
  </div>
</template>

<script>
import User from "../models/User";
import Contact from "../models/Contact";
import Form from "../mixins/Form";

import UserAccountForm from "./UserAccountForm.vue";

export default {
  name: "EvenementForm",
  components: { UserAccountForm },
  mixins: [Form],
  props: {
    evenementIds: Array,
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      contact: new Contact({
        ...User.default(),
        ...this.userData,
        id: null,
        questions: "",
      }),
    };
  },
  computed: {},
  methods: {
    verifyRecaptcha() {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(process.env.MIX_reCAPTCHA_site_key, {
            action: "submit",
          })
          .then((token) => {
            this.contact.recaptchaToken = token;
            this.onSave();
          });
      });
    },
    onSubmit() {
      if (process.env.MIX_RECAPTCHA_ENABLED === "true") {
        this.verifyRecaptcha();
      } else {
        this.onSave();
      }
    },

    async onSave() {
      this.loading = true;

      //be sure to make POST request
      this.contact.id = null;
      this.contact.event_ids = this.evenementIds;

      await this.contact
        .save()
        .then((response) => {
          this.loading = false;

          this.swal({
            icon: "success",
            title: this.$t("events.registration_confirmed_title"),
            text: this.$t("events.registration_confirmed_text"),
          }).then((result) => {
            if (result.isConfirmed) {
              // window.location.href = "/wp-login.php";
            }
          });
        })
        .catch((err) => {
          this.loading = false;
          this.swal({
            icon: "error",
            title: this.$t("Une erreur est servenue"),
            text: `${err.response.data.message}`,
          });
        });
    },
  },
};
</script>
