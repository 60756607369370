var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade show os-modal-register",class:{
      open: _vm.modalOpen,
      'd-none': _vm.hideEmptyModal && _vm.events.length === 0,
    },staticStyle:{"display":"block"},attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-fullscreen"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header border-top border-2 shadow-sm bg-white",on:{"click":_vm.onOpenModal}},[_c('div',{staticClass:"ct-container d-flex"},[_c('div',{staticClass:"flex-grow-1 d-flex align-items-center"},[_c('strong',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.events.length)+" ")]),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.events.length > 1
                    ? "évènements séléctionnés"
                    : "évènement séléctionné")+"\n              ")])]),_vm._v(" "),_c('div',{},[_c('button',{staticClass:"ct-button"},[_vm._v("\n                "+_vm._s(_vm.modalOpen ? "Fermer" : "Valider mes inscriptions")+"\n              ")])])])]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"ct-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.events.length === 0),expression:"events.length === 0"}],staticClass:"p-4 text-center"},[_c('p',[_vm._v("\n                Commencez par sélectionner un ou des évènements dans la liste\n                en cliquant sur \"M'inscrire\".\n              ")])]),_vm._v(" "),_c('div',{staticClass:"row",class:{ 'd-none': _vm.events.length === 0 }},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header bg-white"},[_c('strong',[_vm._v("\n                      "+_vm._s(_vm.$t("summary"))+"\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"card-body"},_vm._l((_vm.events),function(event){return _c('div',{key:event.ID,staticClass:"text-sm text-gray-500"},[_c('p',{staticClass:"m-0 text-lg"},[_c('b',[_vm._v(_vm._s(event.post_title))])]),_vm._v(" "),_c('ul',{staticClass:"fa-ul mb-0",staticStyle:{"margin-left":"1.5rem"}},[(event.acf.evenement_date.date)?_c('li',[_vm._m(0,true),_vm._v("Le\n                          "+_vm._s(_vm.dateFormat(event.acf.evenement_date.date, {
                              timezome: "UTC",
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }))+"\n                          à\n                          "+_vm._s(_vm.dateFormat(event.acf.evenement_date.date, {
                              hour: "2-digit",
                              minute: "2-digit",
                            }))+"\n                        ")]):_vm._e(),_vm._v(" "),(event.acf.evenement_orateurs)?_c('li',[_vm._m(1,true),_vm._v("Par "+_vm._s(event.acf.evenement_orateurs)+"\n                        ")]):_vm._e(),_vm._v(" "),(event.acf.addresse)?_c('li',[_vm._m(2,true),_vm._v(_vm._s(event.acf.addresse)+"\n                        ")]):_vm._e()]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(event.content)}})])}),0)])]),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header bg-white"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("your_information"))+": ")])]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('evenement-form',{attrs:{"evenementIds":_vm.eventIds,"custom-fields":_vm.customFields}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"card-body"},[_vm._t("conditions")],2)])])])])])])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.modalOpen),expression:"modalOpen"}],staticClass:"modal-backdrop fade show"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-li"},[_c('i',{staticClass:"fas fa-calendar-day"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-li"},[_c('i',{staticClass:"fas fa-chalkboard-teacher"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-li"},[_c('i',{staticClass:"fas fa-map-marker-alt"})])
}]

export { render, staticRenderFns }