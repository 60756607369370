import Swal from "sweetalert2";

export default {
  methods: {
    swalToast(options) {
      return this.swal({
        icon: "success",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        ...options,
      });
    },
    swal(options) {
      return Swal.fire({
        ...options,
      });
    },
  },
};
